<template>
  <div id="app">

    <router-view/>
  </div>
</template>

<script>
	export default {
		mounted() {
			// console.log('dodo')
			let lan = window.localStorage.getItem("language")
			let lanN = window.localStorage.getItem("NewLanguage")
			if(!lan && !lanN){
				window.localStorage.setItem("language",'en')
				window.localStorage.setItem("NewLanguage",'cn')
			}
			// window.localStorage.setItem("language",'en')
			// window.localStorage.setItem("NewLanguage",'cn')
			this.adjustZoom(); // 初始化页面加载时的缩放
    	window.addEventListener('resize', this.handleWindowResize);
		},
	
	  watch:{
	
	   $route() {
	         // 路由变化的时候，让滚动数据变为0
	         document.body.scrollTop = 0
	         document.documentElement.scrollTop = 0
	       },
	
	   },
	   methods: {
			handleWindowResize() {
				// 在窗口大小变化时触发的函数
				this.adjustZoom();
			},
			adjustZoom() {
				// return
				//   let screenWidth = window.screen.width;
				let screenWidth = document.getElementsByTagName('html')[0].clientWidth
				// console.log(window)
				  // console.log(screenWidth) // 获取当前屏幕宽度
				screenWidth < 1280 ? '': (screenWidth=1280)
				// console.log(screenWidth)
				let scale = screenWidth / 1280; // 计算缩放比例，假设页面最小宽度为1280px
				if (screenWidth < 1280) {
					// document.body.style.transformOrigin = 'top left';
					document.body.style.display = 'block';
				} else {
					document.body.style.display = 'inline';
				}

				// 应用缩放样式
				document.body.style.transformOrigin = 'top left';
				document.body.style.transform = `scale(${scale})`;
			},
	   }
	
	  
	
	 }
</script>
<style>
@import 'styles/global.scss';
	body{
		font: 14px/1.75 -apple-system,"Helvetica Neue",Helvetica,Arial,sans-serif;
	}
	html {
		width: 100%;
		height: 100%;
	}	
body{
	min-width: 1280px;
	/* font-size: 73.6667px; */
	margin: 0;
	color: rgba(0,0,0,.65);
	display: inline-block;
}
p{
	margin: 0;
}
.drop-down-list .drop-down-item{
	overflow: hidden;
	text-overflow: ellipsis;
}
/* .left-nav p{
	overflow: hidden;
	text-overflow: ellipsis;
} */
</style>
