import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [

	{
	  path: '/',
	  name: 'index',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
	},
	{
	  path: '/about/presidentWords',
	  name: 'presidentWords',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/presidentWords.vue')
	},
	{
	  path: '/about/introduction',
	  name: 'introduction',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/introduction.vue')
	},
	{
	  path: '/about/visitCampus',
	  name: 'visitCampus',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/visitCampus.vue')
	},
	{
	  path: '/about/architectureAndTeam',
	  name: 'architectureAndTeam',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/architectureAndTeam.vue')
	},
	{
	  path: '/about/co',
	  name: 'co',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/about/co.vue')
	},
	{
	  path: '/teaching/sixCollegesList',
	  name: 'sixCollegesList',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/sixCollegesList.vue')
	},
	{
	  path: '/teaching/sixColleges',
	  name: 'sixColleges',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/sixColleges.vue')
	},
	{
	  path: '/teaching/commercial',
	  name: 'commercial',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/commercial.vue')
	},
	{
	  path: '/teaching/igandAlevel',
	  name: 'igandAlevel',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/igandAlevel.vue')
	},
	{
	  path: '/teaching/btec',
	  name: 'btec',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/teaching/btec.vue')
	},
	{
	  path: '/life/campusNews',
	  name: 'campusNews',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/campusNews.vue')
	},
	{
	  path: '/life/TVstation',
	  name: 'TVstation',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/TVstation.vue')
	},
	{
	  path: '/life/calendar',
	  name: 'calendar',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/calendar.vue')
	},
	{
	  path: '/life/TVPlayer',
	  name: 'TVPlayer',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/life/TVPlayer.vue')
	},
	{
	  path: '/planning/service',
	  name: 'service',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/service.vue')
	},
	{
	  path: '/planning/College',
	  name: 'College',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/College.vue')
	},
	{
	  path: '/planning/CollegeInside',
	  name: 'CollegeInside',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/CollegeInside.vue')
	},
	{
	  path: '/planning/foreignCommercial',
	  name: 'foreignCommercial',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/planning/foreignCommercial.vue')
	},
	{
	  path: '/information/story',
	  name: 'story',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/story.vue')
	},
	{
	  path: '/information/rules',
	  name: 'rules',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/rules.vue')
	},
	{
	  path: '/information/scholarship',
	  name: 'scholarship',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/scholarship.vue')
	},
	{
	  path: '/information/qanda',
	  name: 'qanda',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/qanda.vue')
	},
	{
	  path: '/information/apply',
	  name: 'apply',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/apply.vue')
	},
	{
	  path: '/information/message',
	  name: 'message',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/information/message.vue')
	},
	{
		path: '/life/TVPlayerNew',
		name: 'TVNew',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/life/TVPlayerNew.vue')
	  },
	{
	  path: '/contact/Consultation',
	  name: 'Consultation',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/contact/Consultation.vue')
	},
	{
	  path: '/contact/recruit',
	  name: 'recruit',
	  // route level code-splitting
	  // this generates a separate chunk (about.[hash].js) for this route
	  // which is lazy-loaded when the route is visited.
	  component: () => import(/* webpackChunkName: "about" */ '../views/contact/recruit.vue')
	},
	{
		path: '/rcpy2024/',
		name: 'rcpy2024',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/rcpy2024/index.vue')
	  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
